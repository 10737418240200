
import React, { Component } from 'react';
import * as math from 'mathjs'; 
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import excel from"./img/excel.png";
import copy from"./img/copy.png";
import Spinner from 'react-bootstrap/Spinner';

import './wz.scss';

class ReadPdf extends Component {
    constructor(props){
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            copy: false,
            id:this.props.data[0].id
        }
    }
    evaluate = (inputValue) => {
        try {
            const parts = inputValue.split('=');
            const expressionBeforeEquals = parts[0];
            const cleanedExpression = expressionBeforeEquals.replace(/[^()\d/*+.]/g, '');
            const result = math.evaluate(cleanedExpression);
            return typeof result === 'number' && !isNaN(result) ? result : '';
        } catch (error) {
            return '';
        }
    };
    takeScreenshotCopy = async () => {
    await this.setState({copy:true})
  if (this.tableRef.current) {
     const textElements = this.tableRef.current.querySelectorAll('td');

    html2canvas(this.tableRef.current).then(canvas => {
      // Перетворюємо canvas на зображення
      const imageURL = canvas.toDataURL('image/png');

      // Створюємо тимчасовий <img> елемент
      const img = new Image();
      img.src = imageURL;
      img.onload = () => {
        // Створюємо тимчасовий <div> для зображення
        const tempDiv = document.createElement('div');
        tempDiv.contentEditable = true;
        tempDiv.appendChild(img);

        // Додаємо тимчасовий елемент до DOM
        document.body.appendChild(tempDiv);

        // Виділяємо вміст тимчасового <div> і копіюємо його в буфер обміну
        const range = document.createRange();
        range.selectNodeContents(tempDiv);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        try {
            document.execCommand('copy');
        }catch(error) {
            alert('Upss, spróbuj jeszcze')
        }
        // Видаляємо тимчасовий елемент з DOM
        document.body.removeChild(tempDiv);
        this.setState({copy:false})
      };
    });

  }
};
  exportToExcel = () => {
    const data = this.props.data;
    const filteredData = data.map(({name,title,count,subcount}) => ({'Zlecenie':name,'Tytul':title,'Ilości':count,'Ilość palet':subcount}));
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${this.state.id}.xlsx`);

}

    render() {
        
        const column = this.props.data.map((item) => (
            <tr key={item._id}>
                <td contentEditable={true} suppressContentEditableWarning={true}> {item.name}</td>
                <td contentEditable={true} suppressContentEditableWarning={true}>{item.title}</td>
                <td contentEditable={true} suppressContentEditableWarning={true}></td>
                <td contentEditable={true} suppressContentEditableWarning={true}>{item.subcount}</td>
                <td contentEditable={true} suppressContentEditableWarning={true}>{this.evaluate(item.count)}</td>
            </tr>
        ));
        
        
        return (
            <div className="table-wz_edit-header">
                    {this.state.copy ? (<Spinner animation="border"  variant="warning"/>) :<button className="copy_btn hide-on-print" title="Kopiuj" onClick={this.takeScreenshotCopy}><img src={copy} alt="copy"/></button>}
                    <button className="copy_btn hide-on-print" title="Zapisz jako excel"  onClick={this.exportToExcel}> <img src={excel} alt="copy" />  </button>
                <table className="table-wz_edit" ref={this.tableRef}>
                    <thead>
                        <tr>
                            <th>Zlecenie</th>
                            <th>Tytuł</th>
                            <th>Opis</th>
                            <th>Uwagi</th>
                            <th>Ilość</th>
                        </tr>
                    </thead>
                    <tbody>
                        {column}
                    </tbody>
                </table>
            </div>

            )


}
}

export default ReadPdf;