/* eslint-disable array-callback-return */
import React,{ Component} from "react";
import axios from 'axios';

import "./magaz.css";

import { BiHelpCircle ,BiShare} from "react-icons/bi";

import Info from "./app-work/work-place/info/info-pak";
import AppHeader from './header/header';
import WorkNav from "./app-work/work-nav/work-nav";
import WorkPlace from "./app-work/work-place/work-place";
import AppPalety from "./app-work/work-pal/palety";
import Spinner from 'react-bootstrap/Spinner';

class AppMagaz extends Component {
  constructor(props){
    super(props);
    this.state = {
      data:[{order:'actuality'}],
      dataGet:[],
      filter: [{order:'actuality'}],
      filterTable:[],
      buttons: {
        button1: true, // Ваша активна кнопка
        button2: false,
      },
      active:true,
      activeJob: '',
      spiner: false, 
      paletySum:null,
      message:[],
      listData:[],
      activeList:'active',
      activeButtonIndex: 0,
      info: true,
      listItem: 0,
      lastData:[],
      spinerHeader:false,
      timeRemaining: 600,
      host:'https://api.magazyn-stan.fun',
      editStatus: false ,
      plan:[]
    }
    this.timerInterval = null;
}

listItem = (data) => {
  this.setState({listItem: data})
}
editStatus = (data) => {
  this.setState({editStatus: data})
}

activeButtonIndex = (index) => {
    this.setState({activeButtonIndex: index})
}
// message

fetchDataMessage = () => {
    this.setState({spinerHeader:true})
    axios.get(`${this.state.host}/get/message`)
  .then(response => {
    this.setState({ listData: response.data});
    this.setState({spinerHeader:false})

    
  })
  .catch(error => {
    window.alert('wystąpił blad, sprawdź połączenie internetowe')
  });

}

getActiveNote = () => {
    this.state.activeList === "active" ? this.fetchDataMessage() : this.fetchDataMessageAkcept()
}

fetchDataMessageAkcept = () => {
    this.setState({spinerHeader:true})
  axios.get(`${this.state.host}/get/message-akcept`)
    .then(response => {
        this.setState({spinerHeader:false})
      this.setState({ listData: response.data });      
    })
    .catch(error => {
      window.alert('wystąpił blad, sprawdź połączenie internetowe')
    });
  
  }

messageCallback = (data) => {
  this.setState(prevState => ({
        listData: [...prevState.listData, data]
    })) ;

  this.setState(prevState => ({
        message: [...prevState.message, data ]
    }))
 }
messageCallbackList = (data) => {
  this.setState({listData:data}) 
 }
 messageActiveList = (data) => {
  this.setState({activeList:data}) 
 }

calculateSubcountSum = () => {
    if(this.state.data[0].order === 'actuality') {
          let sum = 0;

          // Проходження по всіх об'єктах у масиві
          this.state.filter.forEach((obj) => {
            // Перетворення значення "subcount" в число та додавання до суми
            sum += parseInt(obj.subcount, 10) || 0;
          });
          this.setState({paletySum:sum})
    }
}

authRequest = () => {
  // Перевірка автентифікації під час завантаження компоненту
  const token = localStorage.getItem('token'); // Або ви можете зберігати токен у контексті або стані
  if (!token) {
    // Якщо токен відсутній, перенаправлення на сторінку входу
    this.props.history.push('/');
  }
}


upDataTable = (data, activeJob) => {
  const filterData = data.filter((item )=> item.id === activeJob);
  this.setState({filterTable:filterData})
}

// Оновлено: винесено логіку отримання даних в окремий метод
fetchDataActive =  () => {
    if (this.state.active) {
        this.setState({spiner:true});
        this.getActiveNote();
        this.calculateSubcountSum();
        this.fetchDataPlan()
        axios.get(`${this.state.host}/api/active`)
        .then(response => {
          this.setState({ data: response.data });
          this.setState({ filter: response.data });
          this.state.activeJob === "" ?this.upDataTable(response.data,response.data[0].id) :  this.upDataTable(response.data,this.state.activeJob);
          this.setState({ lastData: response.data.slice(-10)})
          this.setState({spiner:false});
          this.setState({ timeRemaining: 600 });
        })
        .catch(error => {
          window.alert('wystąpił blad, sprawdź połączenie internetowe')
        }); } else if (this.state.active === false) {  
            this.setState({spiner:true})
            axios.get(`${this.state.host}/api/sold`)
            .then(response => {
              this.setState({ filter: response.data });
              this.setState({spiner:false});
              this.interval = null
            })
            .catch(error => {
              window.alert('wystąpił blad, sprawdź połączenie internetowe')
            });
    
    }else {this.interval = null}
}
fetchDataSold = () => {
  this.setState({spiner:true})
  axios.get(`${this.state.host}/api/sold`)
    .then(response => {
      this.setState({ filter: response.data });
      this.setState({spiner:false});
      this.interval = null
    })
    .catch(error => {
      window.alert('wystąpił blad, sprawdź połączenie internetowe')
    });
};

fetchDataPlan = () => {
  axios.get(`${this.state.host}/plan/sold`)
    .then(response => {
      this.setState({ plan : response.data});
    })
    .catch(error => {
      window.alert('wystąpił blad, sprawdź połączenie internetowe')
    });
};

fetchDataSoldTitle = (id ) => {
  this.setState({spiner:true})
  axios.get(`${this.state.host}/api/sold/${id}`)
    .then(response => {
      this.setState({ filterTable: response.data });
      this.setState({spiner:false});
    })
    .catch(error => {
      window.alert('wystąpił blad, sprawdź połączenie internetowe')
    });
};


startTimer = () => {
  this.timerInterval = setInterval(() => {
    if (this.state.timeRemaining > 0) {
      this.setState((prevState) => ({
        timeRemaining: prevState.timeRemaining - 1,
      }));
    }
  }, 1000);
}

resetIntervals = () => {
  // Очищаємо існуючі інтервали
  clearInterval(this.interval);
  clearInterval(this.timerInterval);

  // Запускаємо новий інтервал для автоматичного оновлення даних
  this.interval = setInterval(this.fetchDataActive, 600000); // Оновлення кожні 10 хвилин

  // Скидаємо і запускаємо зворотний таймер
  this.setState({ timeRemaining: 600 });
  this.startTimer();
}
stopIntervals = () => {
  // Зупинка інтервалів
  clearInterval(this.interval);
  clearInterval(this.timerInterval);
}

componentDidMount = () => {
  this.fetchDataActive();

  if (this.state.active === true) {
    this.resetIntervals();
  } else {
    clearInterval(this.interval);
    clearInterval(this.timerInterval);
  }
}


deleteDataFromServer = (id) => {
  axios.delete(`${this.state.host}/delete/${id}`)
    .then(response => {

    this.setState(prevState => ({
    data: prevState.data.filter(item => item._id !== id),
      }));
    })
    .catch(error => {
       window.alert('Wystapil blad')

    });
};

editDataFromServer = (id , newData) => {
  axios.put(`${this.state.host}/put/${id}`, newData)
    .then(response => {

    })
    .catch(error => {
       window.alert('Wystapil blad, brak id')

    });
};

// active button
btnDidMount = () => {

  this.setState({
    buttons: {
      button1: true, 
      button2: false, // Активна кнопка
    },
  });
}


handleButtonClick = (buttonId) => {

  const updatedButtons = { ...this.state.buttons };

  for (const button in updatedButtons) {
    updatedButtons[button] = button === buttonId;
  }


  this.setState({ buttons: updatedButtons });
}

  onTablePush = (id, data) => {
      let indexToRemove = this.state.filter.findIndex(obj => obj._id === id);
    // const updatedData = this.state.data.map(item => item._id === id ? data : item);
    this.state.filter[indexToRemove] = data
    // this.setState({filter:updatedData});
      };
      

  onDataPush = (onDataTable) => {
    this.setState(prevState => ({
      data: [...prevState.data, onDataTable]
    }));
    this.setState(prevState => ({
      filter: [...prevState.filter, onDataTable]
    }));
    };    

onTablePushCallback = (jobName) => {

  const filterData = this.state.filter.filter((item )=> item.id === jobName);
  this.state.buttons.button1 === true ? this.setState({activeJob:jobName}) : this.setState({activeJob:''}) 
  this.setState({filterTable:filterData})
}

  deleteById = (id) => {

  let indexToRemove = this.state.filter.findIndex(obj => obj._id === id);
  

  if (indexToRemove !== -1) {
   
    this.state.filter.splice(indexToRemove, 1);
  }
 }
allJobs = (data) => {
    this.setState({filterTable:data})
}

info = () => {
  this.setState({info:!this.state.info})
}

formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
}




  render() {

    return (
      <div className="App">
        <AppHeader spiner = {this.state.spinerHeader}
                   messageActiveList={this.messageActiveList}
                   activeList = {this.fetchDataMessage}
                   akceptList = {this.fetchDataMessageAkcept}
                   user = {this.props.user} 
                   palety={this.state.palety} 
                   paletySum={this.state.paletySum}
                   fetchDataMessage={this.fetchDataMessage}
                   messageCallback={this.messageCallback}
                   messageCallbackData={this.messageCallbackData}
                   messageCallbackList={this.messageCallbackList}
                   listData = {this.state.listData}
                   lastData= {this.state.lastData}
                   host={this.state.host}
                   tableData={this.state.filterTable}
                   />
        <div className="navigate ">
            <button className={`navigate__item ${this.state.buttons.button1 ? 'active ' : ''}`}
                    title='pobierz aktualne dane'
                    onClick={ async () => {   this.handleButtonClick('button1');
                                        await this.setState({listItem:0, active: true})
                                              this.stopIntervals();
                                              this.componentDidMount();
                                     
                                    }}
                            >Stan magazynowy</button>
            <button className={`navigate__item ${this.state.buttons.button2 ? 'active' : ''}`}
            onClick={ async () =>{ this.handleButtonClick('button2');
                                  await this.setState({active:false, activeJob:'', listItem:0,activeButtonIndex: 0});
                                  this.stopIntervals();
                                  this.fetchDataActive(); 
                        
            }}
                            >Wysłano</button>
        </div>
            <div className="info">  
            
              {this.state.info ?<div className="info-timer"> <button onClick={()=> this.info()}><BiHelpCircle /></button>
                                      <div className="timer_to-apgrate">pobieranie danych za: {this.formatTime(this.state.timeRemaining)}</div></div>: <div className="info-timer"><button onClick={()=> this.info()}> <BiShare /></button> 
                                      <div className="timer_to-apgrate">pobieranie danych za: {this.formatTime(this.state.timeRemaining)}</div></div>}
                {this.state.spiner ? (
                    <Spinner animation="border" variant="warning"/>
                  ) : this.state.info === false ? (
                    <></>
                  ) : (
                    this.state.buttons.button1 ? <AppPalety data={this.state.filter} /> : <></>
                  )}
            </div>
         {this.state.info ? <div className='work'>
                    <WorkNav 
                        editStatus = {this.state.editStatus}
                        fetchDataSoldTitle={this.fetchDataSoldTitle}
                        activeJobBtn={this.state.buttons.button1}
                        listItem={this.listItem}
                        activeIndex= {this.state.activeButtonIndex}
                        activeButtonIndex = {this.activeButtonIndex}
                        upData= {this.onTablePushCallback} 
                        data ={this.state.filter} 
                        allJobs = {this.allJobs}/>                                                                                                       
          <WorkPlace listItem={this.state.listItem}
                     editStatus = {this.editStatus}
                     editStatusEdit = {this.state.editStatus}
                     listItemEdit={this.listItem}     
                     upData={this.onTablePush}
                     deleteBD = {this.deleteDataFromServer}
                     newUpData={this.onDataPush}  
                     allData = {this.state.data}
                     data= {this.state.filterTable} 
                     editItem = {this.editDataFromServer}
                     deleteById = {this.deleteById}
                     user = {this.props.user}
                     palety={this.state.palety}
                     host={this.state.host}
                     plan = {this.state.plan}
                     fetchDataPlan = {this.fetchDataPlan}
                    /> 
        </div> : <Info/> }
      </div>
    )
  }
}


  

export default AppMagaz;